<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-xl-4" style="display: flex; align-items: center; justify-content: center;">
        <div class="bh-card-secondary shadow text-center">
          <img src="../assets/logo.png" alt="" style="width: 100px" /><br />
          <h5>
            Bowhead <br />
            Health
          </h5>
          <div class="text-muted text-center mt-2 mb-3">
            <p>Login with your google acount</p>
          </div>
          <GoogleLogin :callback="Login" /><br />
          <p v-if="error != ''" class="alert-error">{{ error }}</p>
        </div>
      </div>
      <div class="col-8 d-none d-xl-block">
        <img src="../assets/img/bowhead-background.png" style="width:100%; border-radius: 10px" />
      </div>
    </div>
  </div>
</template>
<script>
import { EthHdWallet } from "eth-hd-wallet";
import { required, minLength } from "vuelidate/lib/validators";
import { inject } from "vue";
import { googleTokenLogin, decodeCredential } from "vue3-google-login";
import VueCookies from "vue-cookies";
import LoginService from "@/services/LoginService.js";

require("../lib/ethutil");
require("buffer/").Buffer;

export default {
  name: "login",
  data() {
    const Vue3GoogleOauth = inject("Vue3GoogleOauth");
    return {
      Vue3GoogleOauth,
      model: [
        { word: "" },
        { word: "" },
        { word: "" },
        { word: "" },
        { word: "" },
        { word: "" },
        { word: "" },
        { word: "" },
        { word: "" },
        { word: "" },
        { word: "" },
        { word: "" },
      ],
      trust: false,
      error: "",
      waiting: false,
      isLogin: false,
    };
  },
  validations: {
    word: {
      required,
      minLength: minLength(2),
    },
  },
  methods: {
    async sleep(miliseconds) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, miliseconds);
      });
    },
    Login(response) {
      this.error = "";
      VueCookies.set("jwt", response.credential);
      const userData = decodeCredential(response.credential);

      const data = { name: userData.name, email: userData.email, picture: userData.picture };
      return LoginService.GoogleLogin(data)
        .then(async ({ data }) => {
          window.location.href = "/survey";
        })
        .catch((e) => {
          VueCookies.remove("jwt");
          this.error = e.response.data.response;
        });
    },
  },
};
</script>